import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import img1 from "../images/larktale1.png";

const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Larktale Prams and Strollers E-commerce Website"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `website`, `e-commerce`, `wordpress`, `woocommerce`]}
    />
    <div className="portfolio-header">
      <h1>Larktale Prams & Strollers</h1>
      <h5>E-commerce Website</h5>
      <p>UX/UI design and front-end development of e-commerce website for Larktale.</p>
      <p>Built with Wordpress, WooCommerce and hosted on Amazon Web Services (AWS).</p>
    </div>
    <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://larktale.com" target="_blank" rel="noopener noreferrer">View Project</a>
    </div>
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Homepage</figcaption>
        <img src={img1} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
